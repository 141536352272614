import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/agent/workspace/vOps_provision-documentation_dev/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Welcome to Provision Portal Documentation`}</h1>
    <h2>{`What is Proision Portal?`}</h2>
    <p><a parentName="p" {...{
        "href": "https://provision-portal.stage.bddevops.com/"
      }}>{`Provision Portal`}</a>{` was created to automate deployments of MMS products for Production and Development.`}</p>
    <p>{`It is a web portal, integrated with BD Active Directory Authentication, that enables users to deploy environments with minimal effort of entering a few parameters and clicking a few buttons. Provision Portal is under continual development with new features being added regularly.`}</p>
    <h2>{`Why did we create Provision Portal?`}</h2>
    <p>{`There was, and still is, a need to automate heavily manual processes that require many BD associates, many of whom have “tribal knowledge” about the deployment process. Creating a portal to easliy organize Customer and Deployment infomration with the ability to execute Automation code “behind the scenes” was key to moving towards creating a much improved Customer Experience with BD’s Software Implmentation.`}</p>
    <h2>{`How was it built?`}</h2>
    <p>{`Provision Portal is built using AWS (Amazon Web Services) Cloud services:`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`AWS Systems Manager:`}</em>{` Stores Automation “Documents” and Managed Instance (VM) metadata`}</li>
      <li parentName="ul"><em parentName="li">{`AWS DynamoDB:`}</em>{` Stores customer information, product definitions, and deployment data is a highly scalable, NoSQL database`}</li>
      <li parentName="ul"><em parentName="li">{`AWS Cognito:`}</em>{` Links BD’s Azure AD to AWS for smooth user authentication integration with users current Global ID`}</li>
      <li parentName="ul"><em parentName="li">{`AWS Lambda:`}</em>{` Executes serverless functions that is the code behind Provision Portal`}</li>
    </ul>
    <h2>{`How does it work?`}</h2>
    <p>{`Provision Portal manages Customer information and couples it with product deployments. For now those included ES Server and ES MedStation.`}</p>
    <ol>
      <li parentName="ol">{`Customer account is created by DevOps in AWS so that this customers deployment data and device management is segregated`}</li>
      <li parentName="ol">{`BD Authorized User(s) will be able to add/update Parameters for Deployment`}</li>
      <li parentName="ol">{`The Customer Account page will show information about previous Server deployments (if applicable), Station deployments, Settings and Customer Information`}</li>
      <li parentName="ol">{`User will be able to create a specific Deployment for an ES Server and/or ES MedStation deployment workflow`}</li>
    </ol>
    <ul>
      <li parentName="ul"><em parentName="li">{`Note: Creating a deployment is only a “staging” activity and does not execute any action on the Customers end`}</em></li>
    </ul>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Deployments are uniquely identified with a Deployment Name and ID`}</li>
      <li parentName="ol">{`Deployments require using Customer based Parameters that are stored, securely, for the deployment such as Service User Credentials and SQL Server name(s)`}</li>
      <li parentName="ol">{`When Customer is ready for deployments, a custom `}<inlineCode parentName="li">{`AWS SSMAgent`}</inlineCode>{` service Installer is created that is unique to the deployment via Customer ID and Deployment ID`}</li>
      <li parentName="ol">{`User downloads the Installer and installs it on the Customer VM(s)`}</li>
      <li parentName="ol">{`Once the service starts, the Agent “checks in” with AWS SystemsManager and reports back to Provision Portal as `}<inlineCode parentName="li">{`Ready for Deployment`}</inlineCode></li>
      <li parentName="ol">{`User executes deployment and enjoys a snack or beverage while the automation takes flight`}</li>
      <li parentName="ol">{`Logs are continually streamed to Provision Portal so there is visbility into the process`}</li>
      <li parentName="ol">{`Once deployment is complete, for Stations, Customer can begin work as usual with the newly updated devices. For Servers, the BD User can begin migrating and upgrading data, if required, during the Customers given downtime window`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      